<template>
<Header/>
    <Banner name="Courses" message="Available Courses" />
    <AllCourses/> 
<Footer/>

</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
import AllCourses from '@/components/AllCourses.vue';
export default{
    name:"Courses",
    components:{
        Header,Footer,Banner,AllCourses 
    }
}

</script>